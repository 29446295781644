
main {
  text-align: center;
  vertical-align: center;
  line-height: 100vh
}
#map, #root {
  height: 100%;
  width: 100%;
  background: #000;
}
.leaflet-container {
    background: #000;
}
html, body {
  font-family: 'Lato', sans-serif !important;
  margin: 0;
  height: 100%;
  background: #000;
}